<!--
 * @Description: 侧边-推荐人才
 * @Author: huacong
 * @Date: 2021-07-06 10:35:21
 * @LastEditTime: 2021-07-19 15:27:51
 * @LastEditors: huacong
-->
<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-size:17px;font-weight:bold;">优质人才推荐</span>
      </div>
      <div class="resume-item">
        <div
          class="resumebox"
          v-for="item in partData"
          :key="item.id"
          @click="goResume(item)"
        >
          <el-row>
            <el-col :span="8" class="imgbox">
              <el-image
                style="width: 70px; height: 70px"
                :src="circleUrl"
              ></el-image>
            </el-col>
            <el-col :span="16" class="infobox">
              <p class="fontHover">{{ item.user_name }}</p>
              <p>{{ item.week_province }}-{{ item.week_city }}</p>
              <p>
                {{ item.cer_info[0].certificate }}-{{ item.cer_info[0].major }}
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "recommend",
  data() {
    return {
      circleUrl: require("@/assets/icon/avatar.png"),
      partData: [],
      searchInfo: {},
    };
  },
  computed: {
    ...mapState(["talcer", "talmajor"]),
  },
  methods: {
    ...mapMutations(["changeTalcer", "changeTalmajor"]),
    // 获取人员全职列表
    getParttimeList() {
      let talcer1 = this.$route.query.cer;
      let talmajor1 = this.$route.query.major;
      this.searchInfo.page = 1;
      this.searchInfo.limit = 5;
      this.searchInfo.position_certificate = talcer1;
      this.searchInfo.position_major = talmajor1;
      this.$http
        .post("index/resume/fullresume", this.searchInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.partData = res.data.resultData.list;
          }
        });
    },
    goResume(item) {
      let routeUrl = this.$router.resolve({
        path: "/Home/lookTalResume",
        query: {
          id: item.uid,
          cer: item.position_certificate,
          major: item.position_major,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.getParttimeList();
  },
};
</script>
<style scoped>
.resumebox {
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}
.resume-item .resumebox:last-child {
  border-bottom: 0;
}
.imgbox {
  margin-top: 10px;
}
.infobox p:first-child {
  color: #000;
}
.infobox p:nth-child(2),
.infobox p:nth-child(3) {
  color: #666;
}
.infobox p {
  line-height: 2;
}
</style>
