<template>
  <!-- 人才简历-简历预览 -->
  <div style="padding: 24px 0" class="talentBg">
    <section>
      <!-- 简历信息部分 -->
      <div class="resumeTitle clearfix boxShadow">
        <!-- 头像 -->
        <div class="block talentAvd">
          <el-image
            style="width: 100px; height: 100px"
            :src="circleUrl"
          ></el-image>
        </div>
        <!-- 信息 -->
        <div class="talentInfo">
          <div>
            <span class="name">{{ u_info.u_name }}</span>
          </div>
          <div class="baseInfo">
            <span v-if="u_info.u_sex == 1">男</span>
            <span v-if="u_info.u_sex == 2">女</span>
            <span v-if="base_info">{{ base_info.u_birth }}</span>
            <span
              >{{ resume_info.week_province }}-{{ resume_info.week_city }}</span
            >
            <span v-if="resume_info.work_experience == ''">
              工作经验未填写
            </span>

            <span v-if="base_info.u_title"> 职称:{{ base_info.u_title }} </span>
          </div>
          <div class="contact">
            <!-- <span v-if="base_info.uid">编号：{{ base_info.uid }}</span> -->
            <!-- <span>更新时间：1小时前</span> -->
            <span v-if="resume_info.status">
              {{ resume_info.status }}
            </span>
            <span v-if="!base_info.u_title">
              职称未填写
            </span>
          </div>
        </div>
        <!-- 电话-分享 -->
        <div class="talentTel">
          <div>
            <span class="telbtn" v-show="!phoneShow" @click="getPhone">
              <i class="el-icon-view"></i> 查看电话</span
            >
            <span class="telbtn" v-show="phoneShow">
              <img :src="phoneIcon" alt="" />
              <span>{{ u_info.u_phone }}</span>
            </span>
          </div>
          <p>联系我时请说是在建搜搜上看到的，谢谢</p>
          <p class="shellBox">
            分享至:
            <span style="display: inline-block;padding-left: 10px;">
              <share></share>
            </span>
          </p>
        </div>
      </div>
      <el-row :gutter="16">
        <el-col :span="16">
          <!-- 简历信息 -->
          <div class="resumeInfo resumeInfoList">
            <el-card class="box-card">
              <!-- 求职信息 -->
              <div slot="header" class="clearfix">
                <span style="font-size:17px;font-weight:bold;">求职信息</span>
              </div>
              <div class="resume-item">
                <el-form label-width="100px">
                  <el-form-item label="持有证书情况:" style="margin-bottom:0">
                    <p v-for="item in cer_info" :key="item.id">
                      {{ item.certificate }}-{{ item.major
                      }}<span v-if="item.level != ''">-{{ item.level }}</span
                      >-{{ item.register }}
                    </p>
                  </el-form-item>
                  <el-form-item label="期望城市:" style="margin-bottom:0">
                    <p>
                      {{ resume_info.week_province }}-{{
                        resume_info.week_city
                      }}
                    </p>
                  </el-form-item>
                  <el-form-item label="求职描述:" style="margin-bottom:0">
                    <p style="line-height: 1.5;margin-top: 10px;">
                      {{ resume_info.job_description }}
                    </p>
                  </el-form-item>
                </el-form>
              </div>
              <!-- 基本信息 -->
              <!-- <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">基本信息</span>
                </div>
              </div>
              <div class="resume-item">
                {{ resume_info.job_description }}
              </div> -->
              <!-- 工作经历 -->
              <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">工作经历</span>
                </div>
              </div>
              <div class="resume-item">
                <div
                  class="clearfix workInfoBox"
                  v-for="(item, index) in resume_info.work_experience"
                  :key="index"
                >
                  <div class="infoLeft">
                    <p>
                      {{ item.com_name }}
                      ({{ item.work_time.toString().replace(",", "至") }})
                    </p>
                    <p>
                      <span>工作类型:</span>
                      {{ item.titleType.toString().replace(",", "-") }}
                    </p>
                    <p>
                      <span>工作地点:</span>
                      {{ item.city }}
                    </p>
                    <p>
                      <span>工作内容:</span>
                      {{ item.info }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- 教育经历 -->
              <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">教育经历</span>
                </div>
              </div>
              <div class="resume-item">
                <div
                  class="clearfix workInfoBox"
                  v-for="(item, index) in resume_info.educational_experience"
                  :key="index"
                >
                  <div class="infoLeft">
                    <p>
                      {{ item.school }}
                      ({{ item.edu_time.toString().replace(",", " 至 ") }})
                    </p>
                    <p>
                      <span>
                        {{ item.edu }}
                      </span>
                      <span>
                        {{ item.major }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <!-- 项目名称 -->
              <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">项目名称</span>
                </div>
              </div>
              <div class="resume-item">
                <div
                  class="clearfix workInfoBox"
                  v-for="(item, index) in resume_info.project_experience"
                  :key="index"
                >
                  <div class="infoLeft">
                    <p>
                      {{ item.name }}
                      ({{ item.project_time.toString().replace(",", " 至 ") }})
                    </p>
                    <p>
                      <span>项目职责:</span>
                      {{ item.xmzz }}
                    </p>
                    <p>
                      <span>工作内容:</span>
                      {{ item.gznr }}
                    </p>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <recommend></recommend>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import recommend from "@/view/talentPage/recommend";
import share from "@/components/share/share";
export default {
  name: "resumePre",
  components: {
    recommend,
    share,
  },
  data() {
    return {
      circleUrl: require("@/assets/icon/avatar.png"),
      phoneIcon: require("@/assets/icon/phone.png"),
      kjIcon: require("@/assets/icon/shell-kj.png"),
      qqIcon: require("@/assets/icon/shell-qq.png"),
      wxIcon: require("@/assets/icon/shell-wx.png"),
      wbIcon: require("@/assets/icon/shell-wb.png"),
      u_info: {},
      base_info: {},
      cer_info: [],
      resume_info: {},
      cername: "",
      major: "",
      phoneShow: false,
    };
  },
  computed: {
    ...mapState(["talentInfo", "talentCer", "talentResume"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    getResumeInfo() {
      let uid = "";
      if (this.$route.query.id) {
        uid = this.$route.query.id;
      } else {
        uid = this.userInfo.uid;
      }
      this.$http.post("talent/index/baseinfo", { uid: uid }).then((res) => {
        if (res.data.resultCode === 1) {
          this.u_info = res.data.resultData.u_info;
          if (res.data.resultData.base.length > 0) {
            this.base_info = res.data.resultData.base[0];
          } else {
            this.base_info = {};
          }
          this.cer_info = res.data.resultData.cer;
          this.resume_info = res.data.resultData.resume[0];
        }
      });
    },
    getPhone() {
      if (JSON.stringify(this.userInfo) === "{}") {
        this.$message.warning("登录后才能查看，现在跳转登录！");
        setTimeout(() => {
          this.$router.push("/Login");
        }, 1600);
      } else {
        this.$http
          .post("index/recharge/recharge", { id: this.userInfo.uid })
          .then((res) => {
            if (res.data.resultCode == 1) {
              let vipInfo = res.data.resultData;
              if (
                vipInfo.u_grade == 1 ||
                vipInfo.u_grade == 2 ||
                vipInfo.u_grade == 3 ||
                vipInfo.u_grade == 4
              ) {
                this.phoneShow = true;
              } else {
                this.$message.warning(
                  "您还不是会员，您可以点击右上角手机号前往个人页面-->会员服务购买会员！"
                );
              }
            }
          });
      }
    },
  },
  mounted() {
    this.getResumeInfo();
  },
};
</script>
<style scoped>
/* 简历个人信息 */
.resumeTitle {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}
.talentIndex {
  padding-top: 24px;
}
.demonstration {
  margin-top: 10px;
}
.talentAvd {
  display: block;
  float: left;
}
.talentInfo {
  display: block;
  float: left;
  width: calc(100% - 470px);
  padding: 20px 10px;
}
.talentInfo .name {
  font-size: 17px;
  font-weight: bold;
  padding: 0px 0px;
  display: inline-block;
}
.baseInfo {
  margin-top: 14px;
}
.talentInfo .baseInfo span {
  display: inline-block;
  padding: 0px 16px;
}
.talentInfo .baseInfo span:first-child {
  padding-left: 0;
}
.contact {
  margin-top: 14px;
}
.contact > span {
  padding: 0px 0;
  display: inline-block;
}
.contact span img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.contact > span {
  padding-right: 20px;
}
.talentTel {
  float: right;
  padding: 20px;
  text-align: right;
}
.telbtn {
  display: inline-block;
  padding: 3px 10px;
  background: #2573f1;
  border-radius: 20px;
  color: #fff;
}
.telbtn img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.talentTel p {
  margin-top: 14px;
}
.shellBox img {
  padding: 0px 4px;
}
/* 简历信息 */
.editPencil {
  float: right;
  padding: 3px;
  font-size: 16px;
  color: #2573f1;
  cursor: pointer;
}
.resumeInfo .resume-item {
  padding: 20px;
  min-height: 50px;
}
/* 工作经历 */
.workInfoBox {
  padding: 6px 0px;
}
.workInfoBox p {
  padding: 5px 0;
}
.infoLeft span {
  color: #999;
}
</style>
